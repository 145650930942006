<template>
  <div class="customer-meals-wraper">
    <app-modal-confirm
      ref="entry_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteEntry()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-customer
      @selectCustomer="selectCustomer"
      :endPoint="endPoint"
      :idEditing="idEditing.id ? idEditing.id : undefined"
      :screenFlag="screenFlag"
      ref="modal_list_customer">

    </app-modal-customer>


    <form class="form-horizontal" v-on:submit.prevent="createOrUpdate">
      <div class="card card-body">
        <validation-observer
          ref="global_entry"
          v-slot="{ passes, valid, validated }"
        >
          <div v-if="idEditing.id && idEditing.type">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ globalEntry.id }}</div>
            </div>
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.recorded_by_name") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ globalEntry.recorded_by_name }}</div>
            </div>
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.updated_by_name") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ globalEntry.updated_by_name }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.served_at") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-date-range-picker
                v-if="loading"
                name="served_at"
                v-model="globalEntry.served_at"
                rules="required"
                format="YYYY-MM-DD H:mm"
                @input="globalEntry.served_at = $event"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.apartment") }} <span
              class="app-require">＊</span></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="globalEntry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.customer_member_id") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <div class="row">
                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <app-input
                    name="customer_member_id"
                    rules="required"
                    :disabled="true"
                    v-model="globalEntry.customer_id"
                    :class="['flex-fill']"
                  />
                </div>
                <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <button
                    @click="handleOpenCustomerModal()"
                    class="btn btn-primary w-100"
                    type="button"
                    style="height: 40px !important;"
                  >
                    {{ $t("common.pick_customer") }}
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.note") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-textarea
                name="note"
                type="string1024"
                v-model="globalEntry.note"
              />
            </div>
          </div>
        </validation-observer>
        <div class="form-group row">
          <div class="col-12">
            <div class="card card-primary card-outline card-outline-tabs">
              <div class="card-header p-0 border-bottom-0">
                <ul class="nav nav-tabs mt-2" id="custom-tabs-three-tab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active" id="customer-meals-tab" data-toggle="pill"
                      @click="changeTab(CUSTOMER_MEAL_TYPE)"
                      href="#customer-meals"
                      role="tab" aria-controls="apartments-settings" aria-selected="true">
                      {{ $t("customer-meals.tab_meals") }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="customer-snack-tab" data-toggle="pill"
                       @click="changeTab(CUSTOMER_SNACK_TYPE)"
                       href="#customer-snack" role="tab"
                       aria-controls="apartments-api_key" aria-selected="false">
                      {{ $t("customer-meals.tab_snack") }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="tab-customer-snack">
                  <div class="tab-pane fade active show" id="customer-meals" role="tabpanel"
                       aria-labelledby="customer-meals-tab">
                    <validation-observer
                      ref="meal_entry"
                      v-slot="{ passes, valid, validated }"
                    >
                      <validation-provider
                        name="type_of_three_meals"
                        rules="required"
                        v-slot="{ errors, invalid, validated }"
                      >
                        <div class="form-group row" style="margin-bottom: 0 !important;">
                          <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.type_of_three_meals") }} <span
                            class="app-require">＊</span>
                          </label>
                          <div class="col-xl-5 col-lg-6 col-sm-6 align-self-center">
                            <app-radio v-for="(item, index) in TYPE_OF_THREE_MEALS"
                                       :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                       :val="item.id" v-model="mealEntry.type_of_three_meals"/>
                          </div>
                        </div>
                        <div class="row" style="margin-top: -10px;margin-bottom: 10px;">
                          <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
                          <div class="col-xl-5 col-lg-6 col-sm-6">
                            <label v-if="invalid && validated" class="error">
                              {{ errors[0] }}
                            </label>
                          </div>
                        </div>
                      </validation-provider>
                      <div class="form-group row mt-2">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.type_of_meal") }} <span
                          class="app-require">＊</span>
                        </label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                          <app-input
                            name="type_of_meal"
                            rules="required"
                            v-model="mealEntry.type_of_meal"
                            type="string128"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.amount_of_main") }} <span
                          class="app-require">＊</span>
                        </label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                          <app-select
                            name="amount_of_main"
                            rules="required"
                            :options-data="AMOUNTS"
                            v-model="mealEntry.amount_of_main"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.amount_of_sub") }} <span
                          class="app-require">＊</span>
                        </label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                          <app-select
                            name="amount_of_sub"
                            rules="required"
                            :options-data="AMOUNTS"
                            v-model="mealEntry.amount_of_sub"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.amount_of_soup") }} <span
                          class="app-require">＊</span>
                        </label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                          <app-select
                            name="amount_of_soup"
                            rules="required"
                            :options-data="AMOUNTS"
                            v-model="mealEntry.amount_of_soup"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <app-checkbox
                            :label="$t('customer-meals.is_drink_liquid')"
                            v-model="mealEntry.is_drink_liquid"
                            @input="handlerChangeDrinkWater"
                          />
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.name_of_drink") }}
                          <span v-if="ruleDrink" class="app-require">＊</span>
                        </label>

                        <div class="col-xl-5 col-lg-6 col-sm-6">
                          <app-input
                            name="name_of_drink"
                            type="string128"
                            v-model="mealEntry.name_of_drink"
                            v-if="!ruleDrink"
                          />
                          <app-input
                            name="name_of_drink"
                            type="string128"
                            v-model="mealEntry.name_of_drink"
                            rules="required"
                            v-else
                          />

                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.amount_of_drink") }}
                          <span v-if="ruleDrink" class="app-require">＊</span>
                        </label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                          <app-input
                            name="amount_of_drink"
                            type="number-length:5"
                            v-model="mealEntry.amount_of_drink"
                            :rules="VALIDATE.V_SMALL_INT_UNSIGNED"
                            v-if="!ruleDrink"
                          />
                          <app-input
                            name="amount_of_drink"
                            type="number-length:5"
                            :rules="VALIDATE.V_SMALL_INT_UNSIGNED_REQUIRED"
                            v-model="mealEntry.amount_of_drink"
                            v-else
                          />
                        </div>
                      </div>
                    </validation-observer>

                  </div>
                  <div class="tab-pane fade" id="customer-snack" role="tabpanel"
                       aria-labelledby="customer-snack-tab">
                    <validation-observer
                      ref="snack_entry"
                      v-slot="{ passes, valid, validated }"
                    >
                      <validation-provider
                        name="type_of_meal_snack"
                        rules="required"
                        v-slot="{ errors, invalid, validated }"
                      >
                        <div class="form-group row" style="margin-bottom: 0 !important;">
                          <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.type_of_meal_snack") }} <span
                            class="app-require">＊</span>
                          </label>
                          <div class="col-xl-5 col-lg-6 col-sm-6 align-self-center">
                            <app-radio v-for="(item, index) in TYPE_OF_SNACK" :isMarginLeft="index !=0 ? true : false"
                                       :key="item.id" :label="item.name" :val="item.id"
                                       v-model="snackEntry.type_of_meal" @input="changeSnackTypeOfMeal"/>
                          </div>
                        </div>
                        <div class="row" style="margin-top: -10px;margin-bottom: 10px;">
                          <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
                          <div class="col-xl-5 col-lg-6 col-sm-6">
                            <label v-if="invalid && validated" class="error">
                              {{ errors[0] }}
                            </label>
                          </div>
                        </div>
                      </validation-provider>
                      <div class="form-group row mt-2">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.name_snack") }} <span
                          class="app-require">＊</span>
                        </label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 align-self-center">
                          <app-input
                            name="name_snack"
                            type="string128"
                            v-model="snackEntry.name"
                            rules="required"
                          />
                        </div>
                      </div>
                      <div class="form-group row" v-if="snackEntry.type_of_meal">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.amount_snack") }} <span
                          class="app-require">＊</span>
                        </label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 align-self-center">
                          <app-input
                            name="amount_snack"
                            type="number-length:5"
                            v-model="snackEntry.amount"
                            :rules="VALIDATE.V_SMALL_INT_UNSIGNED_REQUIRED"
                            v-if="snackEntry.type_of_meal == 2"

                          />
                          <validation-provider
                            name="amount_snack"
                            rules="required"
                            v-slot="{ errors, invalid, validated }"
                            v-if="snackEntry.type_of_meal == 1"
                          >
                          <app-select
                            name="amount_snack"
                            rules="required"
                            :options-data="AMOUNTS"
                            v-model="snackEntry.amount"

                          />

                            <label v-if="invalid && validated" class="error">
                              {{ errors[0] }}
                            </label>
                          </validation-provider>
                        </div>
                      </div>
                    </validation-observer>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <div class="card-footer row justify-content-end gr-button">
          <button type="button" class="btn btn-default m-r-10" :disabled="disAbleBtnCreateOrEdit" @click="handlerCancelButton">
            {{ $t('common.cancel') }}
          </button>
          <button class="btn btn-primary" :disabled="disAbleBtnCreateOrEdit" type="submit">
            {{idEditing.id ?  $t('common.save') :  $t('common.create')}}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppModalCustomer from "@components/_common/popup/AppModalCustomer";
  import AppCheckbox from "../../../components/forms/AppCheckbox.vue";
  import {TYPES} from "../../../constants/customer_meals";
  import AppTextarea from "../../../components/forms/AppTextarea";
  import {ENDPOINT} from "@constants/api";

  export default {
    name: "FormPage",

    components: {
      AppTextarea,
      AppModal,
      AppDateRangePicker,
      "app-basic-table": AppBasicTable,
      AppModalCustomer,
      "app-checkbox": AppCheckbox,
    },

    data() {
      const id = this.$route.params.id
      const type = this.$route.params.type
      return {
        mealEntry: {},
        snackEntry: {},
        globalEntry: {
          customer_id: "",
          served_at : moment().utcOffset('+0900').format('YYYY-MM-DD HH:mm')
        },
        meta: {
          apartments: [],
          customers: [],
        },
        idEditing: {
          id: id,
          type: type,
        },
        ruleDrink: false,
        entry: {
          type: 1   // 0 :meal , 1: snack,
        },
        CUSTOMER_MEAL_TYPE: 1,
        CUSTOMER_SNACK_TYPE: 2,
        amountData: 0,
        typeOfMealData : 0,
        loading : true,
        endPoint : ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=",
        screenFlag : 0, // meal is 0, vital is 1, reservation is 2,
        disAbleBtnCreateOrEdit : false,
        checkFirstTimeClickBtn : false
      };
    },

    watch: {
      "globalEntry.apartment_id": async function (value) {
        if (value && this.loading) {
          this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=" + value
          // Clear customer id if not on list
          let checkInArr = false
          await this.$request.get(this.endPoint).then(res => {
            if (!res.hasErrors()) {
              if (res.data.data) {
                let customerId = this.globalEntry.customer_id
                $.each(res.data.data, function(index, customer) {
                  if (customer.id == customerId) {
                    checkInArr = true
                  }
                });
              }
            }
            if (!checkInArr) {
              this.globalEntry.customer_id = ''
            }
          })
        } else {
          this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]="
          this.globalEntry.customer_id = ''
        }
      },
      "globalEntry": {
        handler: async function (newValue) {
          if (this.checkFirstTimeClickBtn) {
            await this.$refs.global_entry.validate()
            this.disAbleBtnCreateOrEdit = !(await this.$refs.global_entry.validate());
          }
        },
        deep: true
      },
      "snackEntry": {
        handler: async function (newValue) {
          if (this.checkFirstTimeClickBtn) {
            await this.$refs.snack_entry.validate()
            this.disAbleBtnCreateOrEdit = !(await this.$refs.snack_entry.validate());
          }
        },
        deep: true
      },
      "mealEntry": {
        handler: async function (newValue) {
          if (this.checkFirstTimeClickBtn) {
            await this.$refs.meal_entry.validate()
            this.disAbleBtnCreateOrEdit = !(await this.$refs.meal_entry.validate());
          }
        },
        deep: true
      }
    },
    mounted: async function () {
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
          customers: res.data.customers,
        }

        this.meta.apartments.unshift({
          id : "",
          name : "--選択--"
        })
      })

      if (this.idEditing.id && this.idEditing.type) {
        this.loading = false;
        await this.$request.get(this.ENDPOINT.CUSTOMER_MEAL_EDIT(this.idEditing.id), {type: TYPES[this.idEditing.type]}).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue();
          } else {
            let data = res.data.data
            this.globalEntry = data.globalEntry
            this.globalEntry.served_at = moment(this.globalEntry.served_at).format('YYYY-MM-DD HH:mm');
            if (data.type === this.CUSTOMER_MEAL_TYPE) {
              this.mealEntry = data.extraEntry
              if (this.mealEntry.is_drink_liquid) {
                this.ruleDrink = true
              }
              $("#customer-snack-tab").removeAttr('data-toggle').removeClass("active")
              $("#customer-meals-tab").addClass('active')
              $("#customer-meals").addClass("active show");
              $("#customer-snack").removeClass("active show");
            }
            if (data.type === this.CUSTOMER_SNACK_TYPE) {
              this.snackEntry = data.extraEntry
              this.amountData = this.snackEntry.amount
              this.typeOfMealData = this.snackEntry.type_of_meal
              $("#customer-meals-tab").removeAttr('data-toggle').removeClass("active")
              $("#customer-snack-tab").addClass('active')
              $("#customer-snack").addClass("active show");
              $("#customer-meals").removeClass("active show");
            }

            this.entry.type = data.type
            this.entry.updated_at = data.updated_at
            this.loading = true
          }
        })
      }
    },

    methods: {
      changeSnackTypeOfMeal(data) {
        this.$refs.snack_entry.reset()
        if (!this.idEditing.id || !this.idEditing.type) {
          this.snackEntry.amount = ""
        } else {
          if (data == this.typeOfMealData) {
            this.snackEntry.amount = this.amountData
          } else {
            this.snackEntry.amount = ""
          }
        }
      },
      changeTab(tab) {
        if (!this.idEditing.id) {
          this.entry.type = tab
        }
      },
      async createOrUpdate() {
        this.checkFirstTimeClickBtn = true;
        if (!await this.$refs.global_entry.validate()) {
          this.disAbleBtnCreateOrEdit = true
          return;
        }
        if (this.entry.type == this.CUSTOMER_MEAL_TYPE) {
          if (!await this.$refs.meal_entry.validate()) {
            this.disAbleBtnCreateOrEdit = true
            return;
          }
        } else {
          this.$refs.snack_entry.reset()
          if (!await this.$refs.snack_entry.validate()) {
            this.disAbleBtnCreateOrEdit = true
            return;
          }
        }

        let res = null;
        let msg = "";
        this.entry.globals = _.cloneDeep(this.globalEntry);
        if (this.entry.type == this.CUSTOMER_MEAL_TYPE) {
          this.entry.extraEntry = _.cloneDeep(this.mealEntry)
        } else {
          this.entry.extraEntry = _.cloneDeep(this.snackEntry)
        }
        if (this.idEditing.id && this.idEditing.type) {
          res = await this.$request.patch(this.ENDPOINT.CUSTOMER_MEAL_UPDATE(this.idEditing.id), this.entry);
          msg = this.$t("common.msg update ok")
        } else {
          res = await this.$request.post(this.ENDPOINT.CUSTOMER_MEAL_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            await this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          await this.redirectDefaultValue()
        }
      },
      handlerChangeDrinkWater(checked) {
        this.ruleDrink = checked
        this.$refs.meal_entry.reset();
        this.$forceUpdate()
      },
      handlerCancelButton() {
        if (localStorage.getItem('meal_search_url')) {
          window.location.href = localStorage.getItem('meal_search_url');
          localStorage.setItem('meal_search_url', null);
        } else {
          this.__redirectBack()
        }
      },
      async redirectDefaultValue() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.CUSTOMER_MEAL_LIST,
          query: {
            'filters.date_equal.between': moment().format('YYYY-MM-DD')
          }
        });
      },
      redirectBack() {
        this.$router.go(-1);
      },
      handleOpenCustomerModal() {
        this.$refs.modal_list_customer.show();
      },
      selectCustomer(data) {
        this.globalEntry.customer_id = data.row.id
        this.$refs.modal_list_customer.hide();
      }
    },
  };
</script>
